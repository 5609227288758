import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="Blog" />
      <article className="max-w-4xl mx-4 xl:m-auto">
        <h1 className="text-4xl md:text-6xl font-bold tracking-tight my-20 text-black dark:text-white">
          Blog
        </h1>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div className="mb-10" key={node.fields.slug}>
              <Link to={node.fields.slug}>
                <h4 className="text-2xl sm:text-3xl font-bold tracking-tight mb-2 dark:text-white">
                  {title}
                </h4>
              </Link>
              <div className="mb-2 max-w-2xl">
                <p
                  className="dark:text-white"
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </div>
              <Link className="underline" to={node.fields.slug}>
                Read more
              </Link>
            </div>
          )
        })}
      </article>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
